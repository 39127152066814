import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { Link, PageProps } from "gatsby";
import DocumentationSection from "components/docs/DocumentationSection";
import CodeExample from "components/docs/code-examples/CodeExample";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import IntegWideTargetFieldConfig from "../../../assets/images/docs/guides/field-mapping/IntegWideTargetFieldConfig.svg";
import PreviewFieldMapping from "../../../assets/images/docs/guides/field-mapping/PreviewFieldMapping.svg";
import LINKS from "components/docs/navigation/links";

/**
 * Creates a Target Fields page for Field Mapping guide
 */
const AccessMappedData = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Access mapped data";
  const description =
    "Access Field Mappings in your Merge Dashboard and through Merge's Unified API";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Dashboard view">
        <>
          <p>
            You can view, edit, and delete mappings made by you or your users in your dashboard in
            the Field Mapping section of each Linked Account's page.
          </p>
          <p>
            Use our preview values feature to double check your mappings for a Linked Account. In
            your user&apos;s Field Mapping page, input an ID associated with one of the Merge&apos;s
            Common Models (eg., and employee ID in Merge). If the mapping is set up correctly, the
            right set of Remote Field values from your user&apos;s application will appear
            automatically.
          </p>
          <div className="flex w-full items-center justify-center my-9">
            <PreviewFieldMapping className="rounded-lg" />
          </div>
          <p className="mb-0">
            You can view, edit, and delete integration-wide mappings in each target field's page.
          </p>
          <div className="flex w-full items-center justify-center my-9">
            <IntegWideTargetFieldConfig className="rounded-lg" />
          </div>
          <p>
            Any Field Mapping changes will be updated in the next sync and will trigger a{" "}
            <b>full resync</b> for the modified Common Models on the next scheduled sync.
          </p>
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="API response" className="mb-9">
        <>
          <p>
            Field Mapping data is automatically accessible in the Common Model responses returned
            from Merge&apos;s Unified API. No parameters are needed.
          </p>
          <p>
            Below is a sample request to the Application Common Model after mapping is complete,
            showing both organization-defined and Linked Account-level targets.
          </p>
          <div className="flex w-full items-center justify-center my-9">
            <CodeExample
              className="w-1/2"
              folder="field-mapping/access-mapped-data"
              codeBlockName="Sample response - Application with Field Mappings"
              fileBaseName="application-with-field-mappings"
              colorScheme="light"
              hasLineNumbers={false}
              isVerticallyPadded={false}
              hideCopyButtonWithHeader
            />
          </div>
          <p>
            You can also use the endpoint{" "}
            <Link to={LINKS.MERGE_FIELD_MAPPING_ENDPOINTS.linkTo}>GET /field-mappings</Link> to
            retrieve all Field Mappings for a Linked Account. See our API reference for all Field
            Mapping endpoints and details (base URL varies by category).
          </p>
        </>
      </DocumentationSection>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default AccessMappedData;
